import { Pipe, PipeTransform } from '@angular/core';
import { millisecondsToHours } from 'date-fns/millisecondsToHours';
import { millisToMinutesAndSeconds } from 'src/app/utility/time';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {

  transform(value: number, onlyHours = false): string {
    if (onlyHours) {
      return millisecondsToHours(value).toFixed(2);
    }
    return millisToMinutesAndSeconds(value);
  }
}
